import { getStyle } from './dom'

/**
 * cropper裁剪框添加放大缩小功能
 * @param cropper cropper实例
 * @param imgRef
 * @param cropperSelectorRef 父级容器
 * @param wheelZoomRatio 放大缩小的比例
 */
export function addCropperBoxWheel(
  cropper,
  {
    imgRef,
    cropperSelectorRef,
  },
  wheelZoomRatio,
) {
  const cropperSpan = cropperSelectorRef.querySelector('.cropper-face')
  const cropperBox = cropperSelectorRef.querySelector('.cropper-crop-box')

  // prevent origin zoom
  imgRef.addEventListener('zoom', (event) => {
    if (event.detail.originalEvent.target === cropperSpan) {
      event.preventDefault()
    }
  })

  // add cropperBox new wheel
  cropperBox.addEventListener('wheel', (event) => {
    const oldWidth = parseInt(getStyle(cropperBox, 'width'))
    const oldHeight = parseInt(getStyle(cropperBox, 'height'))

    let delta
    if (event.deltaY) {
      delta = event.deltaY > 0 ? 1 : -1
    } else if (event.wheelDelta) {
      delta = -event.wheelDelta / 120
    }

    const newWidth = -delta * wheelZoomRatio * oldWidth
    + oldWidth
    const newHeight = -delta * wheelZoomRatio * oldHeight
    + oldHeight

    cropper.setCropBoxData({
      width: newWidth,
      height: newHeight,
    })
  })
}
